.zap-logout {

  &__title,
  &__description,
  &__button,
  &__spinner,
  &__home {
    margin-bottom: 2rem; }

  &__description {
    font-size: 1.125em; }


  &__feedback {
    font-size: 1.125em;
    display: flex;
    margin-bottom: 2rem;

    &:before {
      content: "";
      width: 1rem;
      height: 2rem;
      border-right: 4px solid currentColor;
      border-bottom: 4px solid currentColor;
      display: inline-block;
      transform: rotate(45deg) translate(3px,-4px);
      margin-right: 2rem;
      flex: 0 0 auto; } } }
