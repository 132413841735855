.teaser-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $hbs-eisblau-light; }

.teaser-card__image {
  img {
    max-width: 100%;
    height: auto; } }

.teaser-card__title {
  padding: 2rem 2rem 1rem 2rem;

  * {
    margin: 0; } }

.teaser-card__text {
  flex: 1;
  padding: 0 2rem 1rem 2rem; }

.teaser-card__cta {
  padding: 1rem 2rem 2rem 2rem;

  .btn {
    margin: 0; } }
