.svg-icon {
  svg {
    fill: currentColor; } }

.svg-icon--18 {
  svg {
    width: 18px;
    height: 18px; } }

.svg-icon--24 {
  svg {
    width: 24px;
    height: 24px; } }
