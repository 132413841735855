.article {
  >*+* {
    margin-top: 1.5rem; } }

.article__image {
  img {
    max-width: 100%; }

  figcaption {
    margin-top: 0.5rem;

    span {
      margin-top: 8px;
      display: block;
      font-weight: normal;
      font-size: 0.85em; } } }

.article--image-left,
.article--image-right {

  .article__image {
    @include media-breakpoint-up(md) {
      display: flex; }

    .img-container {
      @include media-breakpoint-up(md) {
        flex: 0 0 66.666%; } }

    figcaption {
      @include media-breakpoint-up(md) {
        align-self: flex-end;
        flex: 0 1 auto;
        margin-top: 0;
        margin-left: 1rem; } } } }

.article--image-small {

  .article__image {
    @include media-breakpoint-up(md) {
      width: 50%; }
    @include media-breakpoint-up(xl) {
      padding-left: 0 !important;
      padding-right: 0 !important; }

    figcaption {
      @include media-breakpoint-up(md) {
        flex: 0 0 50%; } } } }

.article--image-left {
  .article__image {
    @include media-breakpoint-up(xl) {
      padding-right: 12.5%; } } }

.article--image-right {
  .article__image {
    @include media-breakpoint-up(xl) {
      padding-left: 12.5%; }

    figcaption {
      @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: 1rem;
        text-align: right; } } } }

.article--image-right {

  .img-container {
    @include media-breakpoint-up(md) {
      order: 2; }

    figcaption {
      @include media-breakpoint-up(md) {
        text-align: right;
        order: 1;
        margin-left: 0;
        margin-right: 1rem; } } } }

.article__cta {
  margin-top: 1rem;
  .btn {
    margin-right: 0.5rem;
    margin-top: 0.5rem; } }

.article__cta--center {
  text-align: center;

  .btn {
    margin-left: 0.5rem;
    min-width: 280px; } }

.article--image-first {
  display: flex;
  flex-direction: column;

  .article__title {
    order: 2; }

  .article__media {
    order: 1;
    margin-top: 0 !important;
    margin-bottom: 1.5rem !important;
    flex: 0 0 auto; }

  .article__text {
    order: 3; }

  .article__cta {
    order: 4; } }

.article--image-last {
  display: flex;
  flex-direction: column;

  .article__title {
    margin-top: 0 !important;
    order: 1; }

  .article__media {
    order: 4;
    margin-bottom: 0 !important;
    flex: 0 0 auto; }

  .article__text {
    order: 2; }

  .article__cta {
    order: 3; } }
