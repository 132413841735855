.dropdown-menu {
  border-radius: 0;
  border: 0;
  box-shadow: $box-shadow; }

.dropdown--login {
  margin-left: auto;

  .dropdown-menu {
    min-width: 300px;

    .form {
      padding: 1.5rem; } }

  .dropdown-title {
    margin-bottom: 1rem; } }
