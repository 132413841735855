.wysiwyg {
  word-break: break-word;

  > * + * {
    margin-top: 1.5rem; }

  a {
    text-decoration: none;
    border-bottom: 1px solid $hbs-rot;

    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom-color: transparent; } }

  a[href^="http"] {
    margin-right: 5px;
    &:after {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-left: 4px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolygon fill='%23e30513' fill-rule='evenodd' points='4.35 .312 11.838 .312 11.838 7.8 10.362 7.8 10.362 2.843 1.643 11.562 .588 10.507 9.307 1.788 4.35 1.788'/%3E%3C/svg%3E");
      background-size: 12px;
      background-repeat: no-repeat; } }

  ul,
  ol {
    padding: 0;
    list-style-type: none;

    li + li {
      margin-top: 0.5rem; } }

  ul {
    li {
      position: relative;
      padding-left: 1.5rem;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: $primary;
        display: block;
        position: absolute;
        left: 0;
        top: 0.5em; } } }

  ol {
    counter-reset: custom-counter;
    li {
      counter-increment: custom-counter;
      position: relative;
      padding-left: 1.5rem;

      &:before {
        content: counter(custom-counter) ".";
        color: $primary;
        display: block;
        position: absolute;
        left: 0;
        top: 0; } } }

  table {
    width: 100%;

    caption {
      caption-side: bottom;
      margin-bottom: 1rem;
      padding: 1rem 0;
      font-weight: bold;
      color: $dark;
      font-size: rem(14px);

      @include media-breakpoint-up(md) {
        font-size: rem(16px); } }

    td,
    th {
      padding: 1rem 0;
      border-bottom: 1px solid $border-light; } } }
