body {
  margin: 0;
  padding: 0; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 90;
  display: none; }

.js-menu--in {
  .overlay {
    display: block; } }

main {
  min-height: 50vh; }

@supports (display: flex) {
  .page {
    display: flex;
    flex-direction: column;
    min-height: 100vh; }

  main {
    flex: 1; } }
