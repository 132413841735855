.branding {
  background-color: $white;

  .container {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }

  .branding__login {
    display: none;
    order: 2;
    @include media-breakpoint-up(md) {
      display: block; } }

  .branding__logo-primary {
    order: 1;
    margin-right: auto; }
  .branding__logo-secondary {
    order: 3; } }

.logo-my-boeckler {
  font-family: $font-simplebold;
  line-height: 1;
  color: $hbs-rot;
  text-transform: uppercase;
  display: inline-block;
  padding-top: rem(6px);

  &:hover,
  &:focus {
    text-decoration: none;
    color: $hbs-rot; }

  .logo-my {
    font-size: rem(20px);
    display: block;
    margin-left: 3px;
    margin-bottom: -4px;
    letter-spacing: -0.05em; }

  .logo-boeckler {
    font-size: rem(44px);
    display: block;
    letter-spacing: -0.05em; } }

.logo-hbs {
  margin-left: 0;
  @include media-breakpoint-up(md) {
    margin-left: 2rem; }

  img {
    width: 128px;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 160px; } } }
