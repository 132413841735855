@import '../../node_modules/bootstrap/scss/functions';

$font-family-sans-serif: $font-univers;

// $primary: $primary;
// $secondary: $secondary;
// $danger: $error;
// $light: $light;
// $dark: $dark;

// $link-hover-color: $dark;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 704px,
  lg: 960px,
  xl: 1160px
);

$grid-columns:                      12;
$grid-gutter-width:                 32px;

$enable-caret: false;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.


$input-btn-padding-y: rem(10px);
$input-btn-padding-x: rem(10px);

$input-btn-focus-width: .2rem;
$input-btn-focus-color: rgba($secondary, .5);
$input-btn-focus-box-shadow: $focus-shadow;

$input-btn-border-width: 1px;

$input-disabled-bg: lighten($secondary, 35%);

// // Forms

$label-margin-bottom: .3rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-size: rem(16px);
$input-font-weight: normal;
$input-line-height: 1.5;

$input-bg: white;

$input-color: $text-color;
$input-border-color: $secondary;
$input-border-width: 1px;
$input-box-shadow: none;

$input-border-radius: 2px;

$input-focus-bg: $input-bg;
$input-focus-border-color: $secondary;
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-plaintext-color: $text-color;

$input-transition: border-color $timing;

$form-text-margin-top: .25rem;

$form-check-input-gutter: 1.75rem;
$form-check-input-margin-y: .4rem;

$custom-forms-transition: background-color $timing,
 border-color $timing,
 box-shadow $timing;

$custom-control-gutter: 1rem;
$custom-control-spacer-x: 1rem;

$custom-control-indicator-size: rem(18px);
$custom-control-indicator-bg: white;

$custom-control-indicator-bg-size: 70% 70%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $secondary;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg: $input-disabled-bg;

$component-active-bg: $primary;
$component-active-color: white;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: rgba($primary, .4);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow: $input-btn-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $primary;

$custom-checkbox-indicator-border-radius: 2px;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23");

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23");

$custom-select-padding-y: $input-btn-padding-y;
$custom-select-padding-x: $input-btn-padding-x;
$custom-select-height: 46px;
$custom-select-indicator-padding: 2rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: normal;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-bg: white;
$custom-select-indicator-color: $primary;
//$custom-select-indicator: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m0 0h24v24h-24z'/%3E%3Cpath d='m18.12 7-6.12 6.1066667-6.12-6.1066667-1.88 1.88 8 8 8-8z' fill='%23f60'/%3E%3C/g%3E%3C/svg%3E");
// $custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

// $custom-select-border-width: $input-border-width;
// $custom-select-border-color: $input-border-color;
$custom-select-border-radius: 2px;
$custom-select-box-shadow: none;

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: $focus-shadow;


// Popovers

$popover-font-size: rem(20px);
$popover-bg: $primary-dark;
$popover-max-width: 276px;
$popover-border-width: 1px;
$popover-border-color: $primary-dark;
$popover-border-radius: 0;
$popover-box-shadow: none;

$popover-header-bg: $primary-dark;
$popover-header-color: $light;
$popover-header-padding-y: rem(10px);
$popover-header-padding-x: rem(20px);

$popover-body-color: $light;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: rem(18px);
$popover-arrow-height: rem(9px);
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, .05);