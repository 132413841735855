.pi-form {
  display: flex;
  flex-wrap: wrap;
  >* {
    flex: 0 1 100%; } }

.pi-form-control {
  margin-bottom: 1rem;

  a {
    display: inline-block;
    font-weight: bold;
    border-bottom: 1px solid transparent;

    &:before {
      content: "›";
      margin-right: 0.125rem; }

    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom-color: $hbs-rot; } } }

.pi-form-label {
  margin-bottom: 0.25rem; }

.pi-form-button {
  button {
    background-color: $hbs-orange;
    display: inline-flex;
    align-items: center;
    min-height: 44px;
    padding: 0 2rem;
    font-family: $font-univers-cd;
    color: $white;
    font-size: rem(18px);
    border: 0;
    //- IE 11 Flexbox Fix
    &:before {
      content: "";
      height: 44px; } } }

.pi-radio-control,
.pi-checkbox-control,
.pi-radio-control-vertical,
.pi-checkbox-control-vertical {

  input {
    position: absolute;
    left: -999em;
    opacity: 0; }

  label {
    display: inline-block;
    padding-left: 2rem;
    margin-right: 1rem;
    position: relative;
    line-height: 1.25;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid $hbs-tuerkis;
      border-radius: 1px;
      width: 20px;
      height: 20px; } }

  input[disabled] + label,
  input[disabled] + label:before {
    opacity: 0.4 !important; } }


.pi-radio-control,
.pi-radio-control-vertical {
  label:before {
    border-radius: 10px; }

  label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 12px;
    height: 12px;
    background-color: $hbs-tuerkis;
    border-radius: 6px;
    opacity: 0;
    transform: scale(0.1);
    transition: all $timing; }

  input:checked ~ label:after {
    opacity: 1;
    transform: scale(1); } }

.pi-checkbox-control,
.pi-checkbox-control-vertical {
  label:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: 18px;
    height: 18px;
    background-color: $hbs-tuerkis;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpolygon points='0 0 18 0 18 18 0 18'/%3E%3Cpolygon fill='%23FFF' fill-rule='nonzero' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'/%3E%3C/g%3E%3C/svg%3E");
    opacity: 0;
    transform: scale(0.1);
    transition: all $timing; }

  input:checked ~ label:after {
    opacity: 1;
    transform: scale(1); } }

.pi-radio-control-vertical,
.pi-checkbox-control-vertical {
  display: block;
  margin: 0.5rem 0; }

.pi-form-control__has-error {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  select,
  textarea {
    border-color: $hbs-rot; }

  label {
    color: $hbs-rot; } }

.pi-form-error {
  margin-top: 0.25rem;
  color: $hbs-rot;
  font-family: $font-univers-cd;
  font-size: rem(14px);
  text-align: right; }


.pi-form-control__locked {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  select,
  textarea {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M4.5,9.5 L4.5,6 C4.5,2.96243388 6.96243388,0.5 10,0.5 C13.0375661,0.5 15.5,2.96243388 15.5,6 L15.5,9.5 L17,9.5 C18.3807119,9.5 19.5,10.6192881 19.5,12 L19.5,19 C19.5,20.3807119 18.3807119,21.5 17,21.5 L3,21.5 C1.61928813,21.5 0.5,20.3807119 0.5,19 L0.5,12 C0.5,10.6192881 1.61928813,9.5 3,9.5 L4.5,9.5 Z M5.5,9.5 L14.5,9.5 L14.5,6 C14.5,3.51471863 12.4852814,1.5 10,1.5 C7.51471863,1.5 5.5,3.51471863 5.5,6 L5.5,9.5 Z M3,10.5 C2.17157288,10.5 1.5,11.1715729 1.5,12 L1.5,19 C1.5,19.8284271 2.17157288,20.5 3,20.5 L17,20.5 C17.8284271,20.5 18.5,19.8284271 18.5,19 L18.5,12 C18.5,11.1715729 17.8284271,10.5 17,10.5 L3,10.5 Z' transform='translate(2 1)'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right .75rem center; } }


.pi-heading {
  p {
    font-family: $font-simplebold;
    text-transform: uppercase;
    color: $hbs-rot;
    font-size: rem(18px);
    line-height: 1.222;
    margin-bottom: 1.5rem; } }


.pi-message-text,
.pi-form-control.alert.alert-info {
  background-color: $hbs-eisblau;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem; }

.pi-form-control__has-error {
  .pi-message-text {
    background-color: $hbs-rot;
    color: $white;

    a {
      text-decoration: underline;
      font-weight: bold;

      &:after {
        content: " ›"; }

      &:hover,
      &:focus {
        text-decoration: none; } } } }

.login-tools {
  margin-top: 1.5rem;

  a {
    display: inline-block;
    font-weight: bold;
    border-bottom: 1px solid transparent;
    margin-right: 1.5rem;

    &:before {
      content: "›";
      margin-right: 0.125rem; }

    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom-color: $hbs-rot; } } }
