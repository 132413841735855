body {
  font-family: $font-univers;
  font-size: rem(16px);
  line-height: 1.5;
  color: $dark; }

// Margin Resets

dl, ul, ol, p, table, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.5rem; }

* + h1, * + h2, * + h3, * + h4, * + h5, * + h6,
* + .h1, * + .h2, * + .h3, * + .h4, * + .h5, * + .h6 {
  margin-top: 2rem; }

h1, .h1 {
  font-family: $font-simplebold;
  text-transform: uppercase;
  color: $hbs-rot;
  font-size: rem(30px);
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: rem(44px); } }

h2, .h2 {
  font-family: $font-simplebold;
  text-transform: uppercase;
  color: $hbs-rot;
  font-size: rem(24px);
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: rem(30px); } }

h3, .h3 {
  font-family: $font-simplebold;
  text-transform: uppercase;
  color: $hbs-rot;
  font-size: rem(16px);
  line-height: 1.222;

  @include media-breakpoint-up(lg) {
    font-size: rem(18px); } }

h4, .h4 {
  font-family: $font-univers-cd;
  font-size: rem(18px);
  line-height: 1.667;

  @include media-breakpoint-up(lg) {
    font-size: rem(18px); } }

h5, .h5 {
  font-family: $font-univers-cd;
  font-size: rem(18px);
  line-height: 1.75;
  font-weight: bold;

  @include media-breakpoint-up(lg) {
    font-size: rem(18px); } }

h6, .h6 {
  font-family: $font-univers-cd;
  font-size: rem(14px);
  line-height: 1.75;
  font-weight: bold;

  @include media-breakpoint-up(lg) {
    font-size: rem(14px); } }



// Links

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: inherit; } }
