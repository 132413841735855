$spinner-width: 38px;
$spinner-height: 38px;
$spinner-border-width: 4px;

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid $hbs-rot;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }
