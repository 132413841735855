.footer-links {
  background-color: $hbs-sand;
  padding: 2.125rem 0; }

.footer-copyright {
  font-size: 13px;
  padding: 0.75rem 0;
  background-color: $hbs-rot;
  color: $white; }

.footer-linklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 100%;
    display: flex;
    margin-right: 1.5rem;
    @include media-breakpoint-up(xs) {
      flex: 0 1 auto; }

    &:before {
      content: "›";
      flex: 0 0 1rem; } } }

.footer-logo {
  img {
    width: 120px;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 160px; } } }
