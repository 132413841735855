.breadcrumbs {
  background-color: $hbs-rot;
  padding: 1rem 0;
  color: $white; }

.breadcrumb {
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: rem(13px); }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: $white;
  content: "›"; }

.breadcrumb-item {
  &.active {
    color: rgba($white, 0.75); } }
