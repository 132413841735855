.btn {
  background-color: $hbs-orange;
  display: inline-flex;
  align-items: center;
  min-height: 44px;
  padding: 0 2rem;
  font-family: $font-univers-cd;
  color: $white !important;
  font-size: rem(18px);
  border: 0;
  //- IE 11 Flexbox Fix
  &:before {
    content: "";
    height: 44px; }

  .btn__text {
    &:after {
      content: "›";
      display: inline-block;
      margin-left: 0.25em; } }

  &:hover,
  &:focus {
    color: $white;
    box-shadow: $box-shadow; } }

.btn--icon-start {
  .btn__icon {
    margin-top: -2px; }
  .btn__text {
    margin-left: 0.25rem; } }
