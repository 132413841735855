.navigation {
  background-color: $hbs-rot;
  padding: 0;
  box-shadow: inset 0 -1px 0px rgba($white, 0.25);
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  min-height: 60px;

  .container {
    display: flex;
    align-items: center; } }

.js-menu--in .nav-menu {}


.nav-menu {

  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-univers-cd;
  font-size: rem(18px);
  background-color: $white;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  box-shadow: $box-shadow;
  padding: 1rem 0;
  z-index: 20;
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    background-color: transparent;
    position: static;
    box-shadow: none;
    padding: 0; }

  > li {
    flex: 0 0 100%;
    margin-right: 1.5rem;

    @include media-breakpoint-up(md) {
      flex: 0 1 auto; } } }

.js-menu--in {
  .nav-menu {
    display: flex; } }

.nav-menu__item {
  a {
    padding: 1rem;
    display: inline-block;
    border-bottom: 1px solid transparent;

    @include media-breakpoint-up(md) {
      color: $white;
      padding: 1rem 0; }

    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom-color: $white; } } }


.btn-menu {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: 0;
  padding: 0;

  .btn__icon {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px; }

  .btn__bar {
    display: block;
    height: 1px;
    width: 32px;
    background-color: $white;
    position: absolute;
    left: 0;

    &:nth-child(1) {
      top: 8px; }
    &:nth-child(2) {
      top: 16px; }
    &:nth-child(3) {
      top: 24px; } }

  @include media-breakpoint-up(md) {
    display: none; } }

.js-menu--in {
  .btn-menu {
    .btn__bar {
      transition: transform $timing;
      &:nth-child(1) {
        top: 16px;
        transform: rotate(-135deg); }
      &:nth-child(2) {
        display: none; }
      &:nth-child(3) {
        top: 16px;
        transform: rotate(135deg); } } } }

.btn-login {
  font-family: $font-univers-cd;
  font-size: rem(18px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: transparent;
  border: 0;
  padding: 1rem 0;
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom-color: $white; } }

.dropdown--login {
  margin-left: auto;

  h3 {
    margin: 0;
    padding: 1.5rem 0 1.5rem 1.5rem; } }
