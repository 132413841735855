// All HBS Colors
$hbs-rot: rgb(227,5,19) !default;
$hbs-rot-dark: darken($hbs-rot, 15%);
$hbs-tuerkis: rgb(95,187,201) !default;
$hbs-tuerkis-dark: #408088 !default;
$hbs-blau: rgb(0,130,173) !default;
$hbs-lila: rgb(110,53,139) !default;
$hbs-pink: rgb(214,0,126) !default;
$hbs-orange: rgb(239,124,0) !default;
$hbs-sand: rgb(228,225,205) !default;
$hbs-sand-light: lighten($hbs-sand, 10%) !default;
$hbs-eisblau: rgb(215,235,237) !default;
$hbs-eisblau-light: #E9F4F5;
$hbs-grey: rgb(150,150,150) !default;
$hbs-grey-light: rgb(210,210,210) !default;

// Colors
$primary: $hbs-rot !default;
$primary-dark: #595959 !default;
$secondary: #999999 !default;
$secondary-dark: darken($secondary, 5%) !default;
$text-color: #111111 !default;
$dark: #111111 !default;
$light: #F4F4F4 !default;
$error: #C91347 !default;
$success: #36B37E !default;
$white: #FFFFFF !default;

// Border Colors
$border-light: rgba(0,0,0,0.1) !default;
$border-medium: rgba(0,0,0,0.3) !default;
$border-dark: rgba(0,0,0,0.6) !default;

// Shadows
$box-shadpw: 0 8px 16px rgba(0,0,0,0.15) !default;
$focus-shadow: 0 0 0 2px rgba(255,255,255,1), 0 0 0 4px rgba(0,0,0,0.25) !default;

// Timings
$timing: 0.2s cubic-bezier(0.25,0.1,0.25,0.1) 0s !default;

// Fonts
$font-univers: 'Univers', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !default;
$font-simplebold: "Simple-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !default;
$font-univers-cd: 'Univers-Condensed', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !default;
