input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  font-family: $font-univers-cd;
  font-size: rem(18px);
  line-height: 1;
  width: 100%;
  height: rem(40px);
  padding: 0 rem(8px);
  border: 1px solid $hbs-grey;
  border-radius: 1px;

  &:focus {
    border-color: $dark; }

  &[disabled] {
    background-color: $hbs-eisblau-light; } }

select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23111111' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  -webkit-appearance: none;
  appearance: none; }

label {
  font-family: $font-univers-cd;
  font-size: rem(18px);
  line-height: 1; }
